export function navbarLogoutConfirmation() {
    const $logoutButton = $('[data-logout-confirmation]');
    const logoutLink = $logoutButton.data('href');
    const logoutTitle = `<div class="text-center">Logout?</div>`;
    const logoutContent = `
        <div class="p-2 confirm-content">
            <a class="btn btn-danger btn-sm mr-2 confirm-content__button" data-ats-cs-navbar-link="logout_confirm_yes" href="${logoutLink}"><i class="fas fa-check"></i> Yes</a>
            <button class="btn btn-success btn-sm confirm-content__button" data-close-logout-confirmation><i class="fas fa-times no-pointer-events"></i> No</button>
        </div>`;

    $logoutButton.popover({
        sanitize: false,
        html: true,
        title: logoutTitle,
        content: logoutContent,
        placement: 'bottom',
    });

    $(document).on('click', function (e) {
        if (e.target.matches('[data-close-logout-confirmation]')) {
            $logoutButton.popover('hide');
        }
    });

    $('body').on('click', function (e) {
        if (!$logoutButton.is(e.target) && $logoutButton.has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $logoutButton.popover('hide');
        }
    });
}

