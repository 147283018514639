export function validateForm() {
    if ($('[data-form-validation]').length === 0) {
        return false;
    }

    $(document).on('click', '[data-form-validation] [type="submit"]', function (e) {
        const $form = $(this).parents('[data-form-validation]');

        if ($form[0].checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();

            if (!$('.modal.show').length) {
                window.scrollTo({top: 0, behavior: 'smooth'});
            }
        }

        $form.addClass('was-validated');
    });

    $(document).on('loaded.bs.select', function (e) {
        const bootstrapSelectContainer = $(e.target).parent('.bootstrap-select');
        if (bootstrapSelectContainer.next('[data-validation-message]')) {
            bootstrapSelectContainer.next('[data-validation-message]').appendTo(bootstrapSelectContainer);
        }
    });
}
