import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
import 'bootstrap-select';
import 'bootstrap-datepicker';
import {navbarLogoutConfirmation} from './components/navbar/navbar';
import {validateForm} from './components/formValidation/formValidation';

if (window.register) {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': window.register.csrf_token
        }
    });
}

$(function () {
    // enable bootstrap tooltips globally
    $('[data-toggle="tooltip"]').tooltip();

    navbarLogoutConfirmation();
    validateForm();

    $('.custom-file input').change(function () {
        const files = [];
        for (let i = 0; i < $(this)[0].files.length; i++) {
            files.push($(this)[0].files[i].name);
        }
        $(this).next('.custom-file-label').html(files.join(', '));
    });
});
